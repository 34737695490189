import React, { useEffect, useState } from "react";
import "./HubSpotIntegration.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateHubspotIntegration } from "../../redux/slices/duonutSettings";
import { domain } from "../../services/constants";

const HubSpotIntegration = ({ setSelected }) => {
    const dispatch = useDispatch();
    const { title, hubspot_integration } = useSelector(state => state.duonutSettings);
    const { textFormData } = useSelector((state) => state.userActivity);
    const [clickAuthButton, setclickAuthButton] = useState(0);
    const [contactData, setContactData] = useState([]);
    const [accessKey, setaccessKey] = useState("");
    const [refreshKey, setrefreshKey] = useState("");
    const [saveButton, setsaveButton] = useState(false);
    // console.log("textFormData", textFormData, contactData, textFormData.length);

    useEffect(() => {
        if (hubspot_integration?.access_key) {
            setclickAuthButton(2);
            setaccessKey(hubspot_integration.access_key);
            setrefreshKey(hubspot_integration.refresh_key);
            setContactData(hubspot_integration.contact_data);
        }
    }, [hubspot_integration]);

    const handleAuthentication = () => {
        if (clickAuthButton === 2) {
            // console.log("revoke");
            const hubspotData = {}
            dispatch(updateHubspotIntegration(hubspotData));
            setclickAuthButton(0);
            setContactData([]);
            setaccessKey("");
            setrefreshKey("");
            return;
        }
        setclickAuthButton(1);
        const url = 'https://app.hubspot.com/oauth/authorize?client_id=82f4a321-fdd2-4011-87de-0f313bcc08f6&redirect_uri=https://app.duonut.com/hubspotsuccess&scope=crm.objects.contacts.write%20oauth%20crm.objects.contacts.read';

        const newWindow = window.open(url, '_blank', 'width=600,height=600');
        let messageHandled = false;

        // Listen for messages from the new window
        const handleEvent = async (event) => {
            console.log("qqqqq", event, window.location.origin);
            // Check if the message is from the opened window and has the expected origin
            if (event.origin === window.location.origin && !messageHandled) {
                // Close the opened window after some delay (for testing)
                console.log("qqq")
                messageHandled = true;
                window.removeEventListener('message', handleEvent);  // Remove the event listener

                if (event.data.error) {
                    newWindow.close();
                    setSelected(0);
                    return null;
                }

                setTimeout(() => {
                    newWindow.close();
                    // console.log('Child window closed.');
                }, 1000); // Close the window after 2 seconds (for testing)
                // Handle the message (e.g., extract the authorization code)
                const authCode = event.data.authCode;
                console.log('Authorization:', authCode);

                const data = {
                    code: authCode

                };
                // Create the PaymentIntent and obtain clientSecret from your server endpoint
                const res = await fetch(`${domain}/duonut/hubspot_api`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Specify the content type as JSON
                    },
                    body: JSON.stringify(data),
                });

                const { hubspotres } = await res.json();

                // console.log("hubspotres", hubspotres);

                setaccessKey(hubspotres.access_token);
                setrefreshKey(hubspotres.refresh_token);

                if (hubspotres.access_token) {
                    setclickAuthButton(2);
                }

                const hubspotData = {
                    access_key: hubspotres.access_token,
                    refresh_key: hubspotres.refresh_token,
                    contact_data: [{ "property": "email", "value": "" }],
                }
                setContactData([{ "property": "email", "value": "" }]);
                // console.log(hubspotData);
                dispatch(updateHubspotIntegration(hubspotData));

            }

        };

        const handleStorageChange = (event) => {
            if (event.key === 'authCode' && event.newValue && !messageHandled) {
                const authCode = event.newValue;
                console.log('Authorization Code:', authCode);

                messageHandled = true;

                window.addEventListener('storage', handleStorageChange);

                // Clear the local storage item to prevent duplicate handling
                localStorage.removeItem('authCode');

                // Fetch the token using the auth code
                const fetchToken = async () => {
                    const data = { code: authCode };

                    const res = await fetch(`${domain}/duonut/hubspot_api`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });

                    const { hubspotres } = await res.json();

                    // Update state with the obtained tokens
                    setaccessKey(hubspotres.access_token);
                    setrefreshKey(hubspotres.refresh_token);

                    if (hubspotres.access_token) {
                        setclickAuthButton(2);
                    }

                    const hubspotData = {
                        access_key: hubspotres.access_token,
                        refresh_key: hubspotres.refresh_token,
                        contact_data: [{ "property": "email", "value": "" }],
                    };

                    setContactData([{ "property": "email", "value": "" }]);
                    dispatch(updateHubspotIntegration(hubspotData));
                };

                fetchToken();
            }
        };

        window.addEventListener('storage', handleStorageChange, false);

        window.addEventListener('message', handleEvent, false);

    };

    const handleSelectContactProperty = (e, i) => {
        // console.log(e);
        var updatedContactData = [...contactData];
        updatedContactData[i].property = e.target.value;
        setContactData(updatedContactData);
    };

    const handleSelectContactValue = (e, i) => {
        // console.log(e.target.value);
        var updatedContactData = JSON.parse(JSON.stringify(contactData));
        updatedContactData[i].value = String(e.target.value);
        setContactData(updatedContactData);
    };

    const handleAdd = () => {
        const updatedContactData = [...contactData, { "property": "", "value": "" }];
        setContactData(updatedContactData);
    };

    const handleRemove = (num) => {
        var updatedContactData = [...contactData];
        updatedContactData.splice(num, 1);
        setContactData(updatedContactData);
    };

    const handleCompleteIntegration = () => {
        const hubspotData = {
            access_key: accessKey,
            refresh_key: refreshKey,
            contact_data: contactData,
        }
        dispatch(updateHubspotIntegration(hubspotData));

        setsaveButton(true);
        setTimeout(() => {
            setSelected(0);
        }, 1000); //miliseconds

    };



    return (
        <div>
            <div>
                <svg onClick={() => setSelected(0)} style={{ display: "flex", cursor: "pointer" }} width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none"><path d="M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28 16.0517L4.0001 16.0517" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "2rem" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src="/assets/icons/hubspot.svg" style={{ width: "3rem", paddingRight: "1rem" }} alt="duonut" loading="lazy" />
                    <div style={{ textAlign: "left" }}>
                        <h3>Integrate with HubSpot</h3>
                        <div>Send new contacts to your CRM</div>
                    </div>
                </div>
                <button type="submit" key={clickAuthButton} className="SlackIntegrationButton" disabled={clickAuthButton === 1}
                    onClick={() => handleAuthentication()}>{clickAuthButton === 1 ? "Authenticating" : clickAuthButton === 2 ? "Revoke" : "Authenticate"}</button>
            </div>
            {clickAuthButton === 2 && contactData.length > 0 && <div style={{ display: "flex", flexDirection: "column", padding: "2rem 1rem 1rem 4rem", height: "18rem", overflow: "auto" }}>
                <div style={{ textAlign: "left" }}>Email id is a compulsory field in the form, when it needs to be synced with HubSpot.</div>
                <div style={{ fontWeight: "600", padding: "0.5rem" }}>Match your fields</div>
                {contactData?.map((item, i) => {
                    return <div style={{ display: "flex", alignItems: "center", paddingTop: "1rem" }}>
                        <select id="mySelect" style={{
                            padding: "10px", minWidth: "14rem", width: "16rem", cursor: item.property ? "auto" : "pointer",
                            opacity: item.property ? 1 : "inherit", color: "black",
                            border: item.property ? "none" : "1px solid rgb(118, 118, 118)",
                            appearance: item.property ? "none" : "auto",
                        }}
                            value={item.property} disabled={item.property} onChange={(e) => handleSelectContactProperty(e, i)}>
                            <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Please Select</option>
                            <option style={{ padding: "2px" }} value="email">Email Address</option>
                            <option style={{ padding: "2px" }} value="firstname">First Name</option>
                            <option style={{ padding: "2px" }} value="lastname">last Name</option>
                            <option style={{ padding: "2px" }} value="phone">Phone Number</option>
                            <option style={{ padding: "2px" }} value="address">Address</option>
                            <option style={{ padding: "2px" }} value="company">Company</option>
                            <option style={{ padding: "2px" }} value="website">Website</option>
                            <option style={{ padding: "2px" }} value="jobtitle">Job Title</option>
                            <option style={{ padding: "2px" }} value="company_size">Company size</option>
                            <option style={{ padding: "2px" }} value="date_of_birth">Date of birth</option>
                            <option style={{ padding: "2px" }} value="degree">Degree</option>
                            <option style={{ padding: "2px" }} value="field_of_study">Field of study</option>
                            <option style={{ padding: "2px" }} value="gender">Gender</option>
                            <option style={{ padding: "2px" }} value="graduation_date">Graduation date</option>
                            <option style={{ padding: "2px" }} value="hs_buying_role">Buying Role</option>
                            <option style={{ padding: "2px" }} value="hs_content_membership_notes">Membership Notes</option>
                            <option style={{ padding: "2px" }} value="hs_content_membership_status">Status</option>
                            <option style={{ padding: "2px" }} value="hs_email_customer_quarantined_reason">Email address quarantine reason</option>
                            <option style={{ padding: "2px" }} value="hs_facebook_ad_clicked">Clicked Facebook ad</option>
                            <option style={{ padding: "2px" }} value="hs_facebook_click_id">Facebook click id</option>
                            <option style={{ padding: "2px" }} value="hs_google_click_id">Google ad click id</option>
                            <option style={{ padding: "2px" }} value="hs_lead_status">Lead Status</option>
                            <option style={{ padding: "2px" }} value="hs_legal_basis">Legal basis for processing contact's data</option>
                            <option style={{ padding: "2px" }} value="hs_pipeline">Pipeline</option>
                            <option style={{ padding: "2px" }} value="job_function">Job function</option>
                            <option style={{ padding: "2px" }} value="marital_status">Marital Status</option>
                            <option style={{ padding: "2px" }} value="hs_whatsapp_phone_number">WhatsApp Phone Number</option>
                            <option style={{ padding: "2px" }} value="numemployees">Number of Employees</option>
                        </select>
                        <select id="mySelect" style={{ marginLeft: "1rem", padding: "10px", minWidth: "14rem", width: "16rem", cursor: "pointer" }}
                            value={item.value} onChange={(e) => handleSelectContactValue(e, i)}>
                            <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Please Select</option>
                            {Object.keys(textFormData).length > 0 && Object.keys(textFormData).map((key, i) => {
                                return <option style={{ padding: "2px" }} value={key} key={i}>{key}</option>;
                            })}
                        </select>
                        {i + 1 === contactData.length && <div onClick={() => handleAdd()} style={{ marginLeft: "1rem", padding: "0rem 0.5rem", cursor: "pointer" }}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="gray" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6c0-1.10457.89543-2 2-2h8c0 1.10457-.89543 2-2 2H0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0v8c0 1.10457-.89543 2-2 2V2c0-1.104569.89543-2 2-2z"></path></svg>
                        </div>}
                        {contactData.length !== 1 && <div onClick={() => handleRemove(i)} style={{ marginLeft: "0.5rem", padding: "0rem 0.5rem", cursor: "pointer" }}>
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="red" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.43412 8.43418c-.36388.36388-.95384.36388-1.31772 0L.979941 2.29773c-.363879-.36388-.363879-.95385 0-1.317728.363879-.363879.953849-.36388 1.317729 0L8.43412 7.11646c.36388.36388.36388.95384 0 1.31772z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M.979941 8.43418c-.363879-.36388-.36388-.95384 0-1.31772L7.1164.980002c.36388-.363879.95384-.363879 1.31772 0 .36388.363878.36388.953848 0 1.317728L2.29767 8.43418c-.36388.36388-.95385.36388-1.317729 0z"></path></svg>
                        </div>}
                    </div>
                })}

                <button type="submit" className="saveIntegrationButton"
                    onClick={() => handleCompleteIntegration()}>{saveButton ? "Saved" : "Save"}</button>

            </div>}
        </div>
    );
}

export default HubSpotIntegration;