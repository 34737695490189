import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";
import DownArrow from "../../../assets/icons/down-arrow";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import { toggleModal } from "../../../redux/slices/duonutSettings";
// import { toggleDemoModalDuonut } from "../../../redux/slices/DemoReducer";
import { openModal, showSignInForm, showSignUpForm } from "../../../redux/slices/formDetails";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';
import NavItem from "./navItem";
// import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../../redux/slices/ProMessageReducer";

export default function NavItems() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { title } = useSelector((state) => state.duonutSettings);
  // const { isSaved } = useSelector((state) => state.duonutSettings);
  const { dname } = useSelector((state) => state.DuonutName.changeDuonutNameModal);
  const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
  const [activeToolTip, setactiveToolTip] = useState(false);
  const [pathName, setpathName] = useState("");
  const [paramsid, setparamsid] = useState("");

  useEffect(() => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      setactiveToolTip(false);
    } else if (!isLoggedIn) {
      setactiveToolTip(false);
    } else {
      setactiveToolTip(true);
    }

    // Get the current pathname from the window location
    const pathname = location.pathname;

    // Split the pathname into an array of words
    const words = pathname.split('/');

    // Get the last word (assuming the URL ends with a word)
    const lastWord = words[words.length - 1];

    // Use a regular expression to match and capture the numeric part of the URL
    const matchResult = pathname.match(/\/design\/(\d+)/);

    // Check if there is a match and retrieve the captured number
    if (matchResult && matchResult[1]) {
      const extractedNumber = parseInt(matchResult[1]);
      // console.log('Extracted Number:', extractedNumber);
      setparamsid(extractedNumber);
    }
    // Log or use the last word as needed
    // console.log('Last word:', lastWord);

    setpathName(lastWord);
  }, [location.pathname, isLoggedIn]);


  const handleClick = () => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      // dispatch(toggleModal());
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }
  };

  const handleLoginClick = () => dispatch(openModal());

  const handleButtonClick = (url) => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      navigate(url);
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }
  };

  const handleFlowButtonClick = (url) => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      // if (!userProAccess) {
      //   dispatch(toggleProMessageModalDuonut(true));
      //   dispatch(updateProMessageModalMessage('flow editor access'));
      //   return null;
      // }
      navigate(url);
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }

  };

  const handleIntegrationButtonClick = (url) => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      // if (!userProAccess) {
      //   dispatch(toggleProMessageModalDuonut(true));
      // dispatch(updateProMessageModalMessage('integration access'));
      //   return null;
      // }
      navigate(url);
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }
  };

  const handleSchedulesButtonClick = (url) => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      // if (!userProAccess) {
      //   dispatch(toggleProMessageModalDuonut(true));
      // dispatch(updateProMessageModalMessage('integration access'));
      //   return null;
      // }
      navigate(url);
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }
  };

  // const handleClickDemo = () => {
  //   dispatch(toggleDemoModalDuonut(true));
  // };

  let trimmedTitle = "";
  if (title === "") {
    // console.log("dname", dname)
    if (dname) {
      dname.length > 15 ? (trimmedTitle = `${dname.substr(0, 15)}...`) : (trimmedTitle = dname);
    } else {
      trimmedTitle = title;
    }
  } else {
    title.length > 15 ? (trimmedTitle = `${title.substr(0, 15)}...`) : (trimmedTitle = title);
  }

  return (
    <ul className="navbar-container__items" style={{ display: location.pathname.substring(0, 7) === "/design" ? "block" : "", padding: "0px" }}>
      {/* <NavItem text='Home' linkTo='./home' /> */}

      {/* nav items for home page */}

      {location.pathname.substring(0, 7) !== `/design` ? (
        (location.pathname.substring(0, 14) !== "/viewworkflows" && location.pathname.substring(0, 15) !== "/viewworkflow/@") ?
          <>
            {/* {(location.pathname !== "/original") && <NavItem text="Education" linkTo="/education" />} */}
            {/* {(location.pathname !== "/original") && <NavItem text="Create" linkTo="/create" />} */}
            {/* {(location.pathname !== "/" && location.pathname !== "/original" && location.pathname !== "/education") && <NavItem text="All Duonuts" linkTo="/all_duonuts" />} */}
            {/* <NavItem text="What are Duonuts ?" linkTo="/" /> */}
            {/* <NavItem text="About Us" linkTo="/aboutus" /> */}
            {(location.pathname !== "/original") && <NavItem text="Showcase" linkTo="/templates/all" />}
            {(location.pathname !== "/original") && <NavItem text="Pricing" linkTo="/pricing" />}
            {/* {(location.pathname !== "/original") && <NavItem text="Case Studies" linkTo="/mentalhealth" />} */}
            {(location.pathname !== "/original") && <NavItem text="Contact Us" linkTo="/contact" />}
            {(location.pathname !== "/original") && <NavItem text="Blog" linkTo="/blog" />}
            {/* <NavItem text="Pricing" linkTo="/pricing" /> */}
            {/* {(location.pathname !== "/original") && <button className="demoBtnNav"
            onClick={() => handleClickDemo()}>Book a Demo <i className="fa fa-angle-right" aria-hidden="true"></i></button>} */}
          </>
          : <></>
      ) : (
        <div style={{ display: "flex" }}>
          <span data-tip data-for='DuonutName'
            style={{ display: "flex", alignItems: "center", width: "10rem", color: "white" }}>
            {(title || dname) ? trimmedTitle : width > 1100 ? "Untitled Duonut" : "Untitled Duo..."}
          </span>

          <ReactTooltip id="DuonutName" place="bottom" effect="solid">
            {/* <span style={{}}>{title ? title : dname ? dname : trimmedTitle}</span> */}
          </ReactTooltip>

          {/* <button data-tip="Add Slide First" data-for='settingButton' className="settings_title_button" onClick={handleClick}>
            <span>{title ? trimmedTitle : width > 1100 ? "Untitled Duonut" : "Untitled Duo..."}</span>
            <div className="down-arrow">
              <DownArrow fill={width < 1100 ? "white" : "black"} />
            </div>
          </button> */}

          {/* {activeToolTip && <ReactTooltip id="settingButton" place="right" effect="solid" />} */}

          {/* {isLoggedIn ? <div style={{
            display: width > 750 ? "flex" : "none", alignItems: "center", fontSize: "1rem", padding: "0rem 2rem"
          }}>{isSaved ? "Saving changes" : "All changes saved"}</div>
            : <div style={{
              display: width > 750 ? "flex" : "none", alignItems: "center", fontSize: "1rem", padding: "0rem 2rem",
              cursor: "pointer"
            }} onClick={() => {
              handleLoginClick();
              dispatch(showSignInForm());
            }}>
              <svg width="24" height="24" style={{ paddingRight: "0.5rem" }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.323 3.323A.75.75 0 0 0 3.47 4.53L5.51 6.572a5.968 5.968 0 0 0-.502 2.09A5.627 5.627 0 0 0 7 19.498v.001h10v-.001c.436-.01.86-.069 1.266-.172l1.704 1.703a.75.75 0 1 0 1.06-1.06l-.253-.254a.747.747 0 0 1-.307-.186l-16-16a.749.749 0 0 1-.147-.207ZM6.5 9c0-.438.062-.86.179-1.26L16.939 18a4.537 4.537 0 0 1-.189-.002V18h-9.5v-.002a4.125 4.125 0 0 1-.681-8.21A4.528 4.528 0 0 1 6.5 9Z" fill="currentColor"></path><path d="M20.883 17.822a5.625 5.625 0 0 0-3.93-9.571 6.001 6.001 0 0 0-9.632-3.99l1.072 1.07a4.5 4.5 0 0 1 6.99 4.697 4.125 4.125 0 0 1 4.44 6.733l1.06 1.061Z" fill="currentColor"></path></svg>
              Unsaved changes</div>} */}

          <button className={"design_page_nav_buttons"}
            onClick={() => handleButtonClick(`design/${paramsid}`)}>Design
            {(parseInt(pathName) === parseInt(paramsid) || pathName === "design") && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleButtonClick(`design/${paramsid}/settings`)}>Settings
            {pathName === "settings" && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleFlowButtonClick(`design/${paramsid}/flow`)}>Flow
            {pathName === "flow" && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleIntegrationButtonClick(`design/${paramsid}/integrations`)}>Integrations
            {pathName === "integrations" && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleSchedulesButtonClick(`design/${paramsid}/schedules`)}>Schedules
            {pathName === "schedules" && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleButtonClick(`design/${paramsid}/reports`)}>Analytics
            {pathName === "reports" && <div className="design-navbar-selected-line"></div>}
          </button>
          <button data-tip="Need to add slides to access this feature" data-for='settingButton'
            className={"design_page_nav_buttons"}
            onClick={() => handleButtonClick(`design/${paramsid}/analytics`)}>Reports
            {pathName === "analytics" && <div className="design-navbar-selected-line"></div>}
          </button>

          {activeToolTip && <ReactTooltip id="settingButton" multiline={true} place="top" effect="solid" >
            <div>Need to add slides to</div>
            <div>access this feature</div>
          </ReactTooltip>}
        </div>
      )}
    </ul>
  );
}
