import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aiNewModalSetting: {
    active: false,
    aiData: {}
  }
};

const AiSettingNew = createSlice({
  name: "AiSettingNew",
  initialState,
  reducers: {
    toggleAiNewModal: (state, { payload }) => {
      state.aiNewModalSetting.active = payload;
    },
    updateAiNewData: (state, { payload }) => {
      state.aiNewModalSetting.aiData = payload;
    },
  },
});

export const { toggleAiNewModal, updateAiNewData } = AiSettingNew.actions;
export default AiSettingNew.reducer;
