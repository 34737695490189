
// export const backendUrl = "http://127.0.0.1:8000";
export const backendUrl = "https://testingbackend.duonut.com";
// export const backendUrl = "https://backend2.duonut.com";

// export const domain = "http://127.0.0.1:8000/api/v2";
export const domain = "https://testingbackend.duonut.com/api/v2";
// export const domain = "https://backend2.duonut.com/api/v2";

// export const frontendUrl = "http://localhost:3000";
export const frontendUrl = "https://testing.duonut.com";
// export const frontendUrl = "https://app.duonut.com";

// export const domain = process.env.REACT_APP_BASE_URL
// export const domain = process.env.REACT_APP_BASE_URL_TEST;

