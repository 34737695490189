import React, { useEffect, useState } from "react";
import Input from "../../../Input/input";
import PasswordInput from "../../../Input/passwordInput";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { registerUser } from "../../../../services/auth";
import { getUserDetails, loginUser } from "../../../../services/auth";
import { updateIsLoggedIn, updateUserAccountType, updateUserProAccess, updateUserTrialDays } from "../../../../redux/slices/user";
import { defaultDuonut } from "../../../../services/activity";

export default function EmailSignUp({ email, setEmailSignUpActive, onRegisterComplete }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const resetErrors = () => {
    setNameError("");
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrors();
    if (name === "" || password == "" || confirmPassword === "") {
      name === "" && setNameError("This field cant be empty");
      password === "" && setPasswordError("This field cant be empty");
      confirmPassword === "" && setConfirmPasswordError("This field cant be empty");
      return;
    }
    if (password !== confirmPassword) return setConfirmPasswordError("Password don’t match. Please re-enter the passwords. ");
    handleRegister(name, password);
  };

  const handleRegister = (name, password) => {
    const credentials = {
      email: email.trim(),
      password: password.trim(),
      name: name.trim()
    };

    registerUser(credentials, (err, res) => {
      if (res === null) {
        // if (err.response.data.password.length === 1) return setPasswordError('passwords is too common')
        if (err.response.data.password.length === 1) return setPasswordError("Password must be 8 characters long.");
        setPasswordError("passwords is too short");
        return;
      } else {
        // alert("user created");
        resetErrors();
        onRegisterComplete();

        const state = sessionStorage.getItem('state');
        const canvaId = sessionStorage.getItem('canvaId');

        const loginCredentials = {
          email: email.trim(),
          password: password.trim(),
          canvaId: canvaId,
        };
        // console.log("after register", loginCredentials)

        loginUser(loginCredentials, (err, res) => {
          if (err) return;
          if (res !== null) {
            // console.log(res);
            localStorage.setItem("access", res.data.access);
            localStorage.setItem("refresh", res.data.refresh);
            sessionStorage.setItem("access", res.data.access);
            sessionStorage.setItem("refresh", res.data.refresh);
            getUserDetails((err, userDetails) => {
              if (err) return;
              // console.log(userDetails);
              localStorage.setItem("userName", userDetails.data.name);
              localStorage.setItem("userEmail", userDetails.data.email);
              localStorage.setItem("userId", userDetails.data.id);
              localStorage.setItem("appId", userDetails.data.app_id);
              localStorage.setItem("subDomain", userDetails.data.sub_domain);
              localStorage.setItem("proUser", userDetails.data.pro_user);
              localStorage.setItem("userProfilePicture", userDetails.data.profile_picture);
              localStorage.setItem("userType", userDetails.data.user_type);
              localStorage.setItem("expireDate", userDetails.data.expire_date);

              dispatch(updateIsLoggedIn(true));
              dispatch(updateUserAccountType(userDetails.data.user_type ? userDetails.data.user_type : 'free'));
              if (String(userDetails.data.pro_user) === "true") {
                dispatch(updateUserProAccess(true));
                dispatch(updateUserAccountType('pro'));
              } else if (userDetails.data.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(userDetails.data.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                  dispatch(updateUserProAccess(true));
                } else {
                  dispatch(updateUserProAccess(false));
                  dispatch(updateUserAccountType('free'));
                }
              }

              if (userDetails.data.date_joined) {
                // console.log(userDetails.data.date_joined)
                // Get the current date and time
                const currentDate = new Date();

                // Subtract 7 days (7 days * 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
                const currentDateMinus7Days = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

                // Define the date to compare with
                const dateToCompare = new Date(userDetails.data.date_joined);

                // Calculate the difference in milliseconds
                const diffInMilliseconds = Math.abs(dateToCompare - currentDateMinus7Days);

                // Convert milliseconds to days
                const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));

                // console.log(`Difference in days: ${diffInDays}`);

                // Compare the dates
                const comparisonResult = currentDateMinus7Days < dateToCompare;
                // console.log(currentDate, currentDateMinus7Days, dateToCompare, comparisonResult)

                dispatch(updateUserTrialDays(diffInDays));
                //   if (comparisonResult) {
                //   dispatch(updateUserProAccess(true));
                // }
              }

              if (canvaId && state) {
                window.location.replace(`https://www.canva.com/apps/configured?success=true&state=${state}`)
              }

              let reqBody = {}
              defaultDuonut(reqBody, (err, res) => {
                if (err) {
                  console.log(err.response);
                  return;
                }
                console.log(res);
              });

              window.location = "/home";
              // navigate('/onboarding');
            });
          }
        });
      }
    });
  };

  return (
    <div className="form_modal__form email-signup-form mb-4" style={{ marginTop: "15px" }}>
      <div className="form_modal__header form_modal__email_signup-header">
        <div className="modal_back_btn icon-button" onClick={() => setEmailSignUpActive(false)}>
          {`<`}
        </div>
        Sign Up With Email
      </div>
      <p className="form_sub_text">Creativity is intelligence having fun!</p>

      <form onSubmit={handleSubmit}>
        <div className="form_modal__form_fields">
          <label className="form-label">Name</label>
          <Input placeholder="Name" value={name} setValue={setName} errorMsg={nameError} />

          <label className="form-label">Set Password</label>
          <PasswordInput placeholder="Set Password" value={password} setValue={setPassword} errorMsg={passwordError} />

          <PasswordInput placeholder="Confirm Password" value={confirmPassword} setValue={setConfirmPassword} errorMsg={confirmPasswordError} />
        </div>

        <button className="form_modal__submit_btn">Get Started Now !</button>
      </form>
    </div>
  );
}
