import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { updateElementInputFocus } from '../../redux/slices/userActivity';
import { useLocation } from "react-router-dom";

const Matrix = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, questions, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, other, gap,
        labelFontSize, labelFontWeight, labelColor,
    },
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const save_ans_response = useSelector((state) => state.duonut.showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const selectedSlide = useSelector((state) => state.userActivity.selectedSlide);
    const [otherText, setOtherText] = useState('');
    const [isOther, setIsOther] = useState(false);
    const [selectedOption, setSelectedOption] = useState({}); // State to track selected options for each question
    const [hover, setHover] = useState(-1);

    useEffect(() => {
        setIsOther(false);
    }, []);

    const submitResponse = (question, item) => {
        if (location.pathname.includes("/duonut/")) {
            setIsOther(item === "other");

            const save_ans = {
                display_id: viewer_display_id || "", // Fallback if undefined
                watch_id: save_ans_response?.[0]?.watch_id || "", // Fallback if undefined
                result: 0, // Assuming 0 is a default value here
                question: question || "", // Use the passed-in question, fallback to empty string
                answered_text: item || "", // The selected answer
                nickname: viewer_nick_name || "", // Fallback to empty string if undefined
                step: selectedSlide + 1, // Step is current slide + 1
                type: "MatrixGroup", // Hardcoded type
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach((key) => formData.append(key, save_ans[key].toString())); // Ensure all values are strings

            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id: id,
                type: "MatrixGroup",
                step: selectedSlide + 1,
                question: question || "",
                answered_text: item || "",
            };

            dispatch(saveQuizResponse(save_response));
        }
    };

    const submitTextResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: `${item} - ${otherText}`,
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "MatrixGroup",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id: id,
                type: "MatrixGroup",
                step: selectedSlide + 1,
                question: text || "",
                answered_text: `${item} - ${otherText}`,
            };
            dispatch(saveQuizResponse(save_response));
        }
    };

    // const handleOtherInputChange = (e) => {
    //     setOtherText(e.target.value);
    // };

    // const handleInputFocus = () => {
    //     dispatch(updateElementInputFocus(true));
    // };

    // const handleInputBlur = () => {
    //     dispatch(updateElementInputFocus(false));
    // };

    const handleOptionChange = (question, item) => {
        setSelectedOption((prevSelected) => ({
            ...prevSelected,
            [question]: item,
        }));
        submitResponse(question, item);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: gap, padding: "10px" }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {text}
            </div>
            {/* Options as Header */}
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", fontWeight: "bold", fontSize: "16px" }}>
                {/* Empty div to leave space for the questions */}
                <div style={{ width: "150px" }}></div>
                {options.map((item, i) => (
                    <div key={i} style={{ width: "100px", textAlign: "center" }}>
                        {item}
                    </div>
                ))}
            </div>

            <div>
                {/* Questions Rendering */}
                {questions.map((question, questionIndex) => {
                    return (
                        <div key={questionIndex} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            {/* Question Label */}
                            <div id="matrix-questions" style={{ width: "150px", textAlign: "left", fontWeight: "bold", marginRight: "10px" }}>
                                {question}
                            </div>

                            {/* Radio for Options */}
                            <div style={{ display: "flex", justifyContent: "space-between", width: `${options.length * 100}px` }}>
                                {options.map((item, i) => {
                                    const radioId = `radio_option_${slideId}_${questionIndex}_${i}`; // Ensure unique ID for each question/option
                                    return (
                                        <div key={i} style={{ textAlign: "center" }}>
                                            <input
                                                type="radio"
                                                id={radioId}
                                                name={`radio_option_${slideId}_${questionIndex}`}
                                                value={item}
                                                checked={selectedOption[question] === item}
                                                disabled={!location.pathname.includes("/duonut/")}
                                                style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px" }} s
                                                onChange={() => handleOptionChange(question, item)}
                                                onMouseEnter={() => setHover(i)}
                                                onMouseLeave={() => setHover(-1)}
                                                onFocus={() => setHover(i)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Matrix;
