import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import "../../Components/Modals/Form/form.css";
import Home from "../Home/Home";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { openModal } from "../../redux/slices/formDetails";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateIsLoggedIn } from "../../redux/slices/user";
import axios from "axios";
import { domain } from "../../services/constants";

import EmailSignUp from "../../Components/Modals/Form/forms/EmailSignUp";

import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";

import { closeModal, showSignInForm, toggleEnterCodeModal, updateEnterCodeEmail } from "../../redux/slices/formDetails";

import Signin from "../../Components/Modals/Form/forms/signin";
import Signup from "../../Components/Modals/Form/forms/signup";
import EnterCode from "../../Components/Modals/Form/enterCode";
import SuccessModal from "../../Components/Modals/SuccessModal/SuccessModal";

const LoginPage = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginEmail = localStorage.getItem("userEmail");
  const loginName = localStorage.getItem("userName");
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get("state");
  const canvaId = searchParams.get("user");
  // const state = "";
  // const canvaId = "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleClick = () => dispatch(openModal());

  useEffect(() => {
    // console.log(state);
    if (canvaId && state) {
      // console.log("state");
      sessionStorage.setItem('state', state);
      sessionStorage.setItem('canvaId', canvaId);

      if (!isLoggedIn) {
        // handleClick();
        // dispatch(showSignInForm());
      }
    } else {
      if (isLoggedIn) {
        navigate("/home");
        // navigate("/myduonuts");
      } else {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("userName");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userId");
        localStorage.removeItem("withGoogle");
        localStorage.removeItem("withMicrosoft");
        localStorage.removeItem("appId");
        localStorage.removeItem("subDomain");
        localStorage.removeItem("proUser");
        localStorage.removeItem("userProfilePicture");
        localStorage.removeItem("userType");
        localStorage.removeItem("expireDate");

        dispatch(updateIsLoggedIn(false));

        // handleClick();
        // dispatch(showSignInForm());
      }
    }

  }, []);

  const handleAccept = () => {
    const token = localStorage.getItem("access");
    const userId = localStorage.getItem("userId");
    const state = sessionStorage.getItem('state');
    const canvaId = sessionStorage.getItem('canvaId');

    if (canvaId && state) {
      let update_canva_data = {
        canvaId: canvaId,
        id: userId,
      };
      axios({
        method: "POST",
        url: `${domain}/account/updatecanvaid`,
        data: update_canva_data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
        .then((res) => {
          // console.log(res);
          if (canvaId && state) {
            window.location.replace(`https://www.canva.com/apps/configured?success=true&state=${state}`)
          }
        })
        .catch((e) => {
          console.log(e.message, e);
        });
    }

  };

  const handleDiffAcc = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userId");
    localStorage.removeItem("withGoogle");
    localStorage.removeItem("withMicrosoft");
    localStorage.removeItem("appId");
    localStorage.removeItem("subDomain");
    localStorage.removeItem("proUser");
    localStorage.removeItem("userProfilePicture");
    localStorage.removeItem("userType");
    localStorage.removeItem("expireDate");

    dispatch(updateIsLoggedIn(false));

    window.location.reload();
    // handleClick();
    // dispatch(showSignInForm());
  }


  const { modalOpen, signUpactive, enterCodeForm, changePasswordModalActive } = useSelector((state) => state.formDetails);
  const [emailSignUpActive, setEmailSignUpActive] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState(false);
  const [signupEmail, setSignupEmail] = useState(false);
  const [signInError, setSignInError] = useState('')
  const [successModalActive, setSuccessModalActive] = useState(false)

  useDisableBodyScroll(modalOpen);

  const handleClose = () => dispatch(closeModal());

  //signup first stage complete
  const onVerifyEmail = (email, isVerified) => {
    setSignupEmail(email);
    if (isVerified) return setEmailSignUpActive(true);

    dispatch(updateEnterCodeEmail(email));
    const payload = { fromRegister: true };
    dispatch(toggleEnterCodeModal(payload));
  };

  const onRegisterComplete = () => {
    dispatch(showSignInForm());
    setEmailSignUpActive(false)
    handleClose();

    setSuccessModalActive(true)
    setTimeout(() => {
      setSuccessModalActive(false)
    }, 2000);

  };

  return (
    <>
      {isLoggedIn && canvaId && state ? <div className="LoginApp">
        <div className="LoginTemplate">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src="/assets/canvaSLogo.png"></img>
            <i className="fa fa-caret-right" style={{ padding: "0px 0.5rem" }} aria-hidden="true"></i>
            <img src="/assets/logo4.png"></img>
          </div>
          <br></br>
          <div style={{ textDecoration: 'underline', cursor: "pointer" }}
            onClick={() => handleDiffAcc()} >Not {localStorage.getItem("userEmail")}? Choose a different account.</div>
          <br></br>
          <div> Canva wants access to your Duonut account.</div>
          {/* <br></br>
          <h4>Accounts Read</h4>
          <div>Retrieve user information</div>
          <br></br>
          <h4>Accounts Read</h4>
          <div>Retrieve user information</div> */}
          <br></br>
          <button className='LoginAcceptBtn' onClick={() => handleAccept()} >Accept</button>

        </div>

      </div>
        :
        // <Home></Home>
        <div>
          <div className="form_modal">
            {/* <div className="col-lg-6 img-column">
              <div className="form-img-container">
                <img src="/assets/form.png" className="form-img" style={{ maxWidth: "100%", height: "100vh", objectFit: "cover" }} loading="lazy" />
              </div>
            </div> */}

            <div className="form-column">
              {/* <div className="logo" >
                <img style={{ width: "auto", height: "2rem", cursor: "pointer" }}
                  src="/assets/icons/duonut_icon.png" />
                <img style={{ width: "auto", height: "1.5rem", cursor: "pointer", paddingRight: "1.5rem" }}
                src="../../assets/icons/duonut_logo.png" />
              </div> */}

              {emailSignUpActive ? (

                <EmailSignUp
                  setEmailRegistered={setEmailRegistered}
                  setEmailSignUpActive={setEmailSignUpActive}
                  email={signupEmail}
                  onRegisterComplete={onRegisterComplete} />
              ) : signUpactive ? (
                <Signup
                  handleClose={handleClose}
                  onVerifyEmail={onVerifyEmail}
                  setSignInError={setSignInError} />
              ) : (
                <Signin
                  handleClose={handleClose}
                  signInError={signInError}
                  setSignInError={setSignInError} />
              )
              }
            </div>
          </div>

          {enterCodeForm.enterCodeModalActive && <EnterCode setEmailSignUpActive={setEmailSignUpActive} />}
          {successModalActive &&
            <SuccessModal hideHeader={true} hideButtons={true} message={
              <>
                <img src='/assets/icons/tick.svg' />
                <p style={{ marginTop: '24px' }} >User Created</p>
              </>
            }
            />
          }
        </div>
      }
    </>
  );
};


export default LoginPage;
