import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toggleCategoryDropdown } from "../../../redux/slices/activityCategories";
import { useLocation } from "react-router-dom";

var dropdownNum = 0;
export default function NavItem({ text, linkTo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { categoryDropdownActive } = useSelector((state) => state.activityCategories);
  const location = useLocation();
  const [hover, setHover] = useState("");

  const handleClick = () => {
    if (text !== "Case Studies") dropdownNum = 0;
    if (text === "Home") return navigate("/");
    if (text === "Create") {
      // dispatch(toggleCategoryDropdown({active: categoryDropdownActive ? false : true}));
      return navigate("/create");
    }
    if (text == "All Duonuts") return navigate("/all_duonuts");
    if (text == "About Us") return navigate("/aboutus");
    if (text == "Showcase") return window.location.href = 'https://duonut.com/templates'; //navigate("/templates/all");
    if (text == "Contact Us") return window.location.href = 'https://duonut.com/contact'; //navigate("/contactus");
    // if (text == "Blog") return navigate("/blog");
    if (text == "Blog") return window.location.href = 'https://duonut.com/blog';;

    if (text == "Education") return navigate("/education");
    if (text == "Case Studies") {
      if (dropdownNum === 1) return navigate("/mentalhealth");
      if (dropdownNum === 2) return navigate("/edtech");
      if (dropdownNum === 3) return navigate("/d2c");
    }

    if (text == "Pricing") return window.location.href = 'https://duonut.com/pricing'; //navigate("/pricing");
    if (text == "My Duonuts") return navigate("/myduonuts");
  };

  return (
    <li className="navbar-item" onClick={handleClick} style={location.pathname === linkTo ? { fontWeight: "600", position: "relative" } : { position: "relative" }}
      onMouseEnter={() => setHover(linkTo)}
      onMouseLeave={() => setHover("")}>
      {(location.pathname === linkTo || hover === linkTo) && <div className="navbar-selected-line"></div>}
      {text}
      {/* {text === "Create" && <img src="/assets/icons/create-down.svg" className="select-icon" />} */}
      {text === "Case Studies" && <ul className="dropdown-nav">
        <li onClick={() => dropdownNum = 1}>Mental Health</li>
        <li onClick={() => dropdownNum = 2}>Ed-Tech</li>
        <li onClick={() => dropdownNum = 3}>D2C</li>
      </ul>}
    </li>
  );
}
