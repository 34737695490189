import React, { useEffect, useState } from "react";
import "./SlackIntegration.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateSlackIntegration } from "../../redux/slices/duonutSettings";
import { domain } from "../../services/constants";
import { getUserDetails } from "../../services/auth";
import { updateSlackKey } from "../../redux/slices/user";

const SlackIntegration = ({ setSelected }) => {
    const dispatch = useDispatch();
    const { title, slack_integration } = useSelector(state => state.duonutSettings);
    const { slack_key } = useSelector((state) => state.user);
    const [clickAuthButton, setclickAuthButton] = useState(0);
    const [conversations, setConversations] = useState([]);
    const [selectedChannel, setselectedChannel] = useState("");
    const [accessKey, setaccessKey] = useState("");
    const [slackSuccessMsg, setslackSuccessMsg] = useState(false);

    useEffect(() => {
        if (slack_integration?.access_key) {
            // console.log("access111")
            const payload5 = {
                token: slack_integration.access_key,
            }
            axios({
                method: "POST",
                url: "https://slack.com/api/conversations.list",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: new URLSearchParams(payload5),
            }).then((res) => {
                // console.log(res);
                // console.log(res.data.channels);
                if (res.data?.error) {
                    const payload5 = {
                        token: accessKey,
                        client_id: "6161347907313.6148882077250",
                        client_secret: "53337b9f81b8948b924fca5660e749f3",
                    }
                    axios({
                        method: "POST",
                        url: "https://slack.com/api/apps.uninstall",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: new URLSearchParams(payload5),
                    }).then((res) => {
                        console.log(res);

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    const slackData = {}
                    dispatch(updateSlackIntegration(slackData));
                    setclickAuthButton(0);
                    setConversations([]);
                    setselectedChannel("");
                    setaccessKey("");
                    //saving slack access key in user table
                    const data = {
                        userId: localStorage.getItem("userId"),
                    };
                    fetch(`${domain}/duonut/slack_api`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }).then((res) => {
                        // console.log(res);
                        getUserDetails((err, userDetails) => {
                            if (err) return;
                            dispatch(updateSlackKey(userDetails.data.slack_key));

                        });

                    }).catch((e) => {
                        console.log(e.message, e);
                    });
                    return null;
                } else {
                    setConversations(res.data.channels);
                    setclickAuthButton(2);
                }
            }).catch((e) => {
                console.log(e.message, e);
            });

            setaccessKey(slack_integration.access_key);
            setselectedChannel(slack_integration.channel_name);

        } else if (slack_key) {
            // console.log("222")
            const payload5 = {
                token: slack_key,
            }
            axios({
                method: "POST",
                url: "https://slack.com/api/conversations.list",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: new URLSearchParams(payload5),
            }).then((res) => {
                // console.log(res);
                // console.log(res.data.channels);
                if (res.data?.error) {
                    const payload5 = {
                        token: accessKey,
                        client_id: "6161347907313.6148882077250",
                        client_secret: "53337b9f81b8948b924fca5660e749f3",
                    }
                    axios({
                        method: "POST",
                        url: "https://slack.com/api/apps.uninstall",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: new URLSearchParams(payload5),
                    }).then((res) => {
                        console.log(res);

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    const slackData = {}
                    dispatch(updateSlackIntegration(slackData));
                    setclickAuthButton(0);
                    setConversations([]);
                    setselectedChannel("");
                    setaccessKey("");
                    //saving slack access key in user table
                    const data = {
                        userId: localStorage.getItem("userId"),
                    };
                    fetch(`${domain}/duonut/slack_api`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }).then((res) => {
                        // console.log(res);
                        getUserDetails((err, userDetails) => {
                            if (err) return;
                            dispatch(updateSlackKey(userDetails.data.slack_key));

                        });

                    }).catch((e) => {
                        console.log(e.message, e);
                    });
                    return null;
                } else {
                    setConversations(res.data.channels);
                    setclickAuthButton(2);
                }
            }).catch((e) => {
                console.log(e.message, e);
            });

            setaccessKey(slack_key);
            setselectedChannel("");
        } else {
            // console.log("333")
            setclickAuthButton(0);
            setConversations([]);
            setselectedChannel("");
            setaccessKey("");
        }
    }, []);

    const handleAuthentication = () => {
        if (clickAuthButton === 2) {
            // console.log("revoke");
            const payload5 = {
                token: accessKey,
                client_id: "6161347907313.6148882077250",
                client_secret: "53337b9f81b8948b924fca5660e749f3",
            }
            axios({
                method: "POST",
                url: "https://slack.com/api/apps.uninstall",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: new URLSearchParams(payload5),
            }).then((res) => {
                console.log(res);

            }).catch((e) => {
                console.log(e.message, e);
            });


            const slackData = {}
            dispatch(updateSlackIntegration(slackData));
            setclickAuthButton(0);
            setConversations([]);
            setselectedChannel("");
            setaccessKey("");
            //saving slack access key in user table
            const data = {
                userId: localStorage.getItem("userId"),
            };
            fetch(`${domain}/duonut/slack_api`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then((res) => {
                // console.log(res);
                getUserDetails((err, userDetails) => {
                    if (err) return;
                    dispatch(updateSlackKey(userDetails.data.slack_key));

                });

            }).catch((e) => {
                console.log(e.message, e);
            });

            return;
        }
        setclickAuthButton(1);

        ///generate randon string
        const stateLength = 16;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let state = '';
        for (let i = 0; i < stateLength; i++) {
            state += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        const url = `https://slack.com/oauth/v2/authorize?client_id=6161347907313.6148882077250&scope=channels:read,chat:write,chat:write.public&redirect_uri=https://app.duonut.com/slacksuccess&state=${state}`;

        const newWindow = window.open(url, '_blank', 'width=600,height=600');
        let messageHandled = false;
        // console.log('before calling11', messageHandled);

        // Listen for messages from the new window
        const messageHandler = (event) => {
            // Check if the message is from the opened window and has the expected origin
            // console.log('before calling22', messageHandled);
            if (event.origin === window.location.origin && !messageHandled) {
                messageHandled = true;
                window.removeEventListener('message', messageHandler);  // Remove the event listener

                if (event.data.error) {
                    newWindow.close();
                    setSelected(0);
                    return null;
                }
                // Close the opened window after some delay (for testing)
                setTimeout(() => {
                    // console.log('Authorization Code: newWindow', newWindow);
                    newWindow.close();
                }, 1000); // Close the window after 2 seconds (for testing)


                // Handle the message (e.g., extract the authorization code)
                const authCode = event.data.authCode;
                // console.log('Authorization Code:', authCode);

                const payload = {
                    code: authCode,
                    client_id: "6161347907313.6148882077250",
                    client_secret: "53337b9f81b8948b924fca5660e749f3",
                }
                axios({
                    method: "POST",
                    url: "https://slack.com/api/oauth.v2.access",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: new URLSearchParams(payload),
                }).then((res) => {
                    // console.log(res);
                    // console.log(res.data.access_token);
                    setaccessKey(res.data.access_token);

                    //saving slack access key in user table
                    const data = {
                        key: res.data.access_token,
                        userId: localStorage.getItem("userId"),
                    };
                    fetch(`${domain}/duonut/slack_api`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }).then((res) => {
                        // console.log(res);
                        getUserDetails((err, userDetails) => {
                            if (err) return;
                            dispatch(updateSlackKey(userDetails.data.slack_key));

                        });

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    // geting channel name
                    const payload1 = {
                        token: res.data.access_token,
                    }
                    axios({
                        method: "POST",
                        url: "https://slack.com/api/conversations.list",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: new URLSearchParams(payload1),
                    }).then((res) => {
                        // console.log(res);
                        if (res.data?.error) {
                            return null;
                        }
                        // console.log(res.data.channels);
                        setConversations(res.data.channels);
                        setclickAuthButton(2);
                        setslackSuccessMsg(true);
                        setTimeout(() => {
                            setslackSuccessMsg(false);
                        }, 5000);

                    }).catch((e) => {
                        console.log(e.message, e);
                    });


                }).catch((e) => {
                    console.log(e.message, e);
                });

            }
        };
        window.addEventListener('message', messageHandler, false);
    };


    const handleSelectChannel = (e) => {
        // console.log(e);
        setselectedChannel(e.target.value);
        let channel_id;
        let channel_name;
        conversations.map((item) => {
            if (item.name === e.target.value) {
                channel_id = item.id;
                channel_name = item.name;
            }
        });
        const slackData = {
            access_key: accessKey,
            channel_id: channel_id,
            channel_name: channel_name,
        }
        // console.log(slackData);
        dispatch(updateSlackIntegration(slackData));

        if (accessKey) {
            var userName = localStorage.getItem("userName");
            var userEmail = localStorage.getItem("userEmail");
            // var textdata = `Hello, it's Duonut. Whenever someone completes the *${title}* duonut, I'll share their answers with you here.`;
            var textdata;

            if (userName) {
                textdata = `${userName} has added *${title}* Duonut to ${channel_name} channel.`
            } else {
                textdata = `${userEmail} has added *${title}* Duonut to ${channel_name} channel.`
            }

            const payload = {
                token: accessKey,
                channel: channel_id,
                text: textdata,
            }
            axios({
                method: "POST",
                url: "https://slack.com/api/chat.postMessage",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: new URLSearchParams(payload),
            }).then((res) => {
                // console.log(res);
            }).catch((e) => {
                console.log(e.message, e);
            });
        }
    };

    const handleDisconnect = () => {
        const slackData = {}
        dispatch(updateSlackIntegration(slackData));
        setselectedChannel("");
    }

    // console.log("slackSuccessMsg", slackSuccessMsg);
    return (
        <div key={clickAuthButton}>
            <div key={slackSuccessMsg}>
                <svg onClick={() => setSelected(0)} style={{ display: "flex", cursor: "pointer" }} width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none"><path d="M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28 16.0517L4.0001 16.0517" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                {slackSuccessMsg && <div style={{ color: "green", fontSize: "1.2rem" }}>Connection with Slack has been established.</div>}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "2rem" }}>
                <label style={{ display: "flex", alignItems: "center" }}><img src="/assets/icons/slack.svg" style={{ width: "3rem", paddingRight: "1rem" }} alt="duonut" loading="lazy" />Integrate with Slack </label>
                <button type="submit" key={clickAuthButton} className="SlackIntegrationButton" disabled={clickAuthButton === 1}
                    onClick={() => handleAuthentication()}>{clickAuthButton === 1 ? "Authenticating" : clickAuthButton === 2 ? "Revoke" : "Authenticate"}</button>
            </div>
            {clickAuthButton === 2 && conversations?.length > 0 && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                <label>Slack Channels </label>
                <select id="mySelect" style={{ padding: "10px", minWidth: "11rem", cursor: "pointer" }}
                    value={selectedChannel} onChange={(e) => handleSelectChannel(e)}>
                    <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Choose Channel</option>
                    {conversations?.map((item, i) => {
                        return <option style={{ padding: "2px" }} value={item.name}>{item.name}</option>
                    })}
                </select>
            </div>}
            {selectedChannel && <button type="submit" className="SlackIntegrationButton"
                style={{ marginTop: "2rem", float: "right", backgroundColor: "#DB615C" }}
                onClick={() => handleDisconnect()}>
                <i className="fa fa-trash-o" aria-hidden="true" style={{ marginRight: "10px" }}></i>
                Disconnect Channel</button>}
        </div>
    );
}

export default SlackIntegration;