import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";

export default function SingleOption({ item, idx }) {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [question, setQuestion] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        setQuestion(item);
    }, [item]);

    const handleQuestionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;
        setQuestion(newText)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            // console.log(elementAttrib);
            // Make a copy of the questions array and update the text of the question at index 'indx'
            const updatedQuestions = elementAttrib.questions.map((question, index) => {
                if (index === indx) {
                    return newText;
                }
                // console.log(question);
                return question;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    questions: updatedQuestions,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const removeQuestion = (indx) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of questions array and remove the question at index 'indx'
            const updatedQuestions = elementAttrib.questions.filter((question, index) => index !== indx);

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    questions: updatedQuestions,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px"
        }} key={idx}  >
            <div style={{ marginRight: "1rem" }}>Question{idx + 1}</div>
            <EditText
                id={`question-input-${idx}`}
                value={question}
                style={{
                    border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                    display: "flex", justifyContent: "flex-start", alignItems: "center",
                    width: "13rem", height: "1.8rem", padding: "5px"
                }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#question-input-${idx}`).select(), 100); }}
                onChange={(e) => (e.target.value.length < 0 ? null : handleQuestionTextChange(e, idx))}
            />

            <i className="fa fa-trash-o" aria-hidden="true"
                style={{ marginLeft: "0.6rem", fontSize: "1rem", color: "#DB615C", cursor: "pointer" }} onClick={() => removeQuestion(idx)}></i>
        </div>
    )
}

