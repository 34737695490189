import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";
import SingleOption from './singleOption';
import SingleQuestionOption from './singleQuestionOption';

export default function OptionQuestion() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [options, setOptions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const dispatch = useDispatch()
    const [isOther, setisOther] = useState(false);

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            setOptions(tempElement?.elementAttributes?.options);
            setQuestions(tempElement?.elementAttributes?.questions);
            setisOther(tempElement?.elementAttributes?.other);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleOptionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of the options array and update the text of the option at index 'indx'
            const updatedOptions = elementAttrib.options.map((option, index) => {
                if (index === indx) {
                    return newText;
                }
                return option;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const addOption = () => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: [...elementAttrib.options, `Option ${elementAttrib.options.length + 1}`],
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const addQuestion = () => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            // console.log(elementAttrib);
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    questions: [...elementAttrib.questions, `Question ${elementAttrib.questions.length + 1}`],
                },
            };
            // console.log(tempElement);
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const removeOption = (indx) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of options array and remove the option at index 'indx'
            const updatedOptions = elementAttrib.options.filter((option, index) => index !== indx);

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    return (
        <div className="EditorRowContainer" style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ margin: "0.5rem 0rem", fontWeight: "700" }}>Options</div>
            {options?.map((item, i) => {
                return <SingleOption item={item} idx={i} />
            })}
            <button type='submit' className='addoptionbtn' onClick={() => addOption()} >Add option</button>
            <div style={{ margin: "0.5rem 0rem", fontWeight: "700" }}>Questions</div>
            {questions?.map((item, i) => {
                return <SingleQuestionOption item={item} idx={i} />
            })}
            <button type='submit' className='addoptionbtn' onClick={() => addQuestion()} >Add question</button>
        </div>
    )
}

