import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";

export default function OpenEnded() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [openEnded, setopenEnded] = useState(false);
    const [openEndedObjective, setopenEndedObjective] = useState("");
    const [openEndedFeedbackDesired, setopenEndedFeedbackDesired] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const openEnded = tempElement?.elementAttributes?.openEnded;
            const openEndedObjective = tempElement?.elementAttributes?.openEndedObjective;
            const openEndedFeedbackDesired = tempElement?.elementAttributes?.openEndedFeedbackDesired;
            if (openEnded) {
                setopenEnded(openEnded);
                setopenEndedObjective(openEndedObjective);
                setopenEndedFeedbackDesired(openEndedFeedbackDesired);
            } else {
                setopenEnded(false);
                setopenEndedObjective("");
                setopenEndedFeedbackDesired("");
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleOpenEndedChange = () => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEnded: !openEnded
                },
            };
            console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleObjetiveChange = (e) => {
        console.log(e)
        const value = e.target.value;
        setopenEndedObjective(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedObjective: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const handleFeedbackDesiredChange = (e) => {
        console.log(e)
        const value = e.target.value;
        setopenEndedFeedbackDesired(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedFeedbackDesired: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleFeedbackDesiredClick = (value) => {
        console.log(value)
        setopenEndedFeedbackDesired(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedFeedbackDesired: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (<>
        <div className="EditorRowContainer" style={{ margin: "1rem 0px" }}>
            <input type='checkbox' id='openEndedField' name='openEndedField'
                style={{ width: "20px", height: "20px", margin: "2px 8px 2px 2px" }}
                checked={openEnded ? true : false}
                onChange={() => handleOpenEndedChange()} />

            <label style={{ marginRight: "1rem" }} for="openEndedField">Open-Ended?</label>

        </div>
        {openEnded && <> <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Objetive</div>
            <EditTextarea rows={3}
                id="Objetive-input-field"
                value={openEndedObjective}
                style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "15rem", paddingLeft: "5px" }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#Objetive-input-field`).select(), 100); }}
                onChange={(e) => (handleObjetiveChange(e))}
                placeholder="Write your Objetive."
            />
        </div>
            <div className="EditorRowContainer">
                <div style={{ marginRight: "0rem" }}>Feedback desired</div>
                <EditTextarea rows={3}
                    id="Feedback-input-field"
                    value={openEndedFeedbackDesired}
                    style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "24rem", paddingLeft: "5px" }}
                    onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                    onChange={(e) => (handleFeedbackDesiredChange(e))}
                    placeholder="Write your Feedback desired."
                />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on the overall visual attractiveness of the creative asset.")}>
                    Visual appeal</label>
                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on the clarity and impact of the message conveyed.")}>
                    Message clarity</label>
                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on how well the asset aligns with our brand identity.")}>
                    Brand consistency</label>
            </div>
        </>}
    </>
    )
}

