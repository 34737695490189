import React, { useState, useEffect, useRef } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus, setTextFormData } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, saveFormData } from '../../redux/slices/DuonutReducer';
import { getOpenEndedQuestionAsync } from "../../redux/slices/AiDuonutNewReducer";
import { showduonutData } from '../../redux/slices/DuonutReducer';

var aiCount = 0;
const QuestionAnswer = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius,
    minLength, maxLength, required, placeholder, borderBottom, height, openEnded, openEndedObjective,
    openEndedFeedbackDesired,
  },
  conditions,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide, textFormData } = useSelector((state) => state.userActivity);
  const location = useLocation();
  const [textField, setTextField] = useState('');
  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name, save_form_data } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const [inputText, setInputText] = useState(text);
  const [loading, setloading] = useState(false);

  const duonutDataMain = useSelector(showduonutData);
  const duonutData = duonutDataMain[0];

  useEffect(() => {
    setInputText(text);

    var formData = {};
    formData[text] = id;
    // console.log(formData);
    dispatch(setTextFormData(formData));
  }, [text]);

  const handleQuestionAnswerTextChange = (e) => {
    // console.log(e.target.value);
    setInputText(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const QuestionAnswerTimeout = useRef(null);

  const handleQuestionAnswerInputChange = (e) => {
    if (location.pathname.includes("/duonut/")) {
      setTextField(e.target.value);

      // clearTimeout(QuestionAnswerTimeout.current);
      // QuestionAnswerTimeout.current = setTimeout(() => {

      //   var formData1 = {};
      //   formData1[text] = e.target.value;
      //   // console.log(formData);
      //   dispatch(saveFormData(formData1));

      //   var save_ans = {
      //     display_id: viewer_display_id,
      //     watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
      //     result: e.target.value ? 1 : 0,
      //     question: inputText,
      //     answered_text: e.target.value,
      //     // end: lastQue ? "1" : "0",
      //     nickname: viewer_nick_name,
      //     step: selectedSlide + 1,
      //     type: "QuestionAnswer",
      //     // country: location[0] ? location[0].address?.country : "",
      //     // city: location[0] ? location[0].address?.state_district : "",
      //     // location: location[0] ? location[0].display_name : "",
      //     // url_params: props.details.queryParamsObject
      //   }
      //   // console.log(save_ans);
      //   const formData = new FormData();
      //   Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

      //   dispatch(saveQuizAnsAsync(formData));

      //   var save_response = {
      //     id: id,
      //     type: "QuestionAnswer",
      //     step: selectedSlide + 1,
      //     question: inputText,
      //     answered_text: e.target.value,
      //   }
      //   dispatch(saveQuizResponse(save_response));

      // }, 1000);

    }
  }

  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  const submitResponse = () => {
    if (location.pathname.includes("/duonut/") && !openEnded) {
      var save_ans = {
        display_id: viewer_display_id,
        watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
        result: 1,
        question: inputText ? inputText : "",
        answered_text: textField,
        // end: lastQue ? "1" : "0",
        nickname: viewer_nick_name,
        step: selectedSlide + 1,
        type: "QuestionAnswer",
        // country: location[0] ? location[0].address?.country : "",
        // city: location[0] ? location[0].address?.state_district : "",
        // location: location[0] ? location[0].display_name : "",
        // url_params: props.details.queryParamsObject
      }
      console.log(save_ans);
      const formData = new FormData();
      Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

      dispatch(saveQuizAnsAsync(formData));

      var save_response = {
        type: "QuestionAnswer",
        step: selectedSlide + 1,
        question: inputText ? inputText : "",
        answered_text: textField,
      }
      dispatch(saveQuizResponse(save_response));

      var nextPage = selectedSlide + 1;

      setTimeout(() => {
        dispatch(updateGoToSlide(nextPage));
      }, 100);

    } else if (location.pathname.includes("/duonut/") && openEnded) {
      console.log("question", inputText, textField);

      var userIsPro = false;
      if (duonutData?.created_by.pro_user) {
        userIsPro = true;
      } else if (duonutData?.created_by.expire_date) {
        const currentDate = new Date();
        const dateToCompare = new Date(duonutData?.created_by.expire_date);
        const comparisonResult = currentDate < dateToCompare;
        if (comparisonResult) {
          userIsPro = true;
        } else {
          userIsPro = false;
        }
      }
      if ((!userIsPro && aiCount < 2) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

        setloading(true);

        var get_ans = {
          question: inputText,
          answer: textField,
          objective: openEndedObjective,
          feedbackDesired: openEndedFeedbackDesired,
        }

        dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
          console.log(res);
          if (res?.question) {
            console.log(res?.question);
            setInputText(res?.question)
            document.getElementById("qainput").value = "";
            setloading(false);

            var save_ans = {
              display_id: viewer_display_id,
              watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
              result: 1,
              question: inputText ? inputText : "",
              answered_text: textField,
              // end: lastQue ? "1" : "0",
              nickname: viewer_nick_name,
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              type: "QuestionAnswer",
              // country: location[0] ? location[0].address?.country : "",
              // city: location[0] ? location[0].address?.state_district : "",
              // location: location[0] ? location[0].display_name : "",
              // url_params: props.details.queryParamsObject
            }
            console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
              type: "QuestionAnswer",
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              question: inputText ? inputText : "",
              answered_text: textField,
            }
            dispatch(saveQuizResponse(save_response));

            aiCount++;

          }
        }).catch((e) => {
          console.log(e.message, e);
        });

      } else {
        var save_ans = {
          display_id: viewer_display_id,
          watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
          result: 1,
          question: inputText ? inputText : "",
          answered_text: textField,
          // end: lastQue ? "1" : "0",
          nickname: viewer_nick_name,
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          type: "QuestionAnswer",
          // country: location[0] ? location[0].address?.country : "",
          // city: location[0] ? location[0].address?.state_district : "",
          // location: location[0] ? location[0].display_name : "",
          // url_params: props.details.queryParamsObject
        }
        console.log(save_ans);
        const formData = new FormData();
        Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

        dispatch(saveQuizAnsAsync(formData));

        var save_response = {
          type: "QuestionAnswer",
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          question: inputText ? inputText : "",
          answered_text: textField,
        }
        dispatch(saveQuizResponse(save_response));

        var nextPage = selectedSlide + 1;

        setTimeout(() => {
          dispatch(updateGoToSlide(nextPage));
        }, 100);
      }

    }
  }

  return (
    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: "column", height: height }}>
      <EditText
        id={`text-field-input-${id}`}
        value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          // display: "flex",
          // alignItems: "center",
          width: "100%",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => {
          handleInputFocus();
          // setTimeout(() => document.querySelector(`#text-field-input-${id}`).select(), 100); 
        }}
        onChange={(e) => handleQuestionAnswerTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
      <div style={{ display: "flex" }}>
        <input type='text' key={borderBottom} id='qainput'
          style={{
            width: "100%", minHeight: "40px",
            color, fontFamily, fontSize: fontSize - 4, fontStyle, textDecoration, textTransform,
            textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
            backgroundColor, border: borderBottom ? "0" : border, margin, borderRadius, borderBottom: border
          }}
          onChange={(e) => handleQuestionAnswerInputChange(e)}
          readOnly={location.pathname.includes("/duonut/") ? false : true}
          required={required}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
        />
        <button onClick={() => submitResponse()}
          style={{ backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem', cursor: "pointer" }}>
          {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
            : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
            </svg>}
        </button>
      </div>
    </div>
  );
}

export default QuestionAnswer;
